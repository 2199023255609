import React, { useState, useEffect, useCallback } from "react"
import SlideToggle from "react-slide-toggle"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Modal } from "react-bootstrap"
import SocialLinks from "./SocialLinks"
import { isLoggedIn } from "../../../services/auth"

const MainSidebar = ({ show, handleClose }) => {
  const menuItemsRaw = useStaticQuery(graphql`
    {
      allSilverStripeDataObject(
        filter: { SilverStripeSiteTree: { title: { ne: null } } }
        sort: { fields: SilverStripeSiteTree___sort, order: ASC }
      ) {
        nodes {
          SilverStripeSiteTree {
            urlSegment
            title
            menuTitle
            showInMenus
            showInSearch
            sort
            parentID
            Children {
                SilverStripeSiteTree{
                  urlSegment
                  title
                  menuTitle
                  showInMenus
                  showInSearch
                  sort
                }
            }
          }
        }
      }
    }
  `)
  const menuItems = menuItemsRaw.allSilverStripeDataObject.nodes
  var allMenus = []
  var i = 0
  for (; menuItems[i]; ) {
    let menuItem = menuItems[i].SilverStripeSiteTree
    let showInMenus = menuItem.showInMenus
    let parentID = menuItem.parentID
    if (showInMenus != 0 && parentID == 0) {
      allMenus.push(menuItem)
    }
    i++
  }
  allMenus.sort((a, b) => {
    return a.sort * 1 > b.sort * 1 ? 1 : -1
  })

  let isClose = true
  const [isActive, setActive] = useState(isClose)
  const handleToggle = () => {
    setActive(!isClose)
  }
  const [logged, setLogged] = useState(false)
    useEffect(() => {
        const isLogged = isLoggedIn()
        setLogged(isLogged)
    }, []);
    
  return (
    <>
      <Modal
        className="mSidebar"
        show={show}
        onHide={handleClose}
        animation={true}
      >
        <div className="mSidebar-inner">
          <Modal.Header closeButton={true}></Modal.Header>
          {allMenus && (
            <ul className="mSidebar-menu">              
              {allMenus.map((menu, idx) => (
                <React.Fragment key={`menu-${idx}`}>
                {menu.Children ? (
                    <SlideToggle key={`dropdown-menu-${idx}`} collapsed={isClose}>
                        {({ toggle, setCollapsibleElement, toggleState }) => (
                          <li className="has-item">
                            <a href={`/${menu.urlSegment}`}>{menu.menuTitle}</a>
                            <div
                              className={`toggle-item${toggleState == "EXPANDED" ? " active" : ""}`}
                              onClick={e => {
                                toggle(e)
                                handleToggle()
                                e.preventDefault()
                              }}
                            ></div>
                            <ul className="menu-item" ref={setCollapsibleElement}>
                                {menu.Children.map((childMenu, childIdx) => (
                                    <li key={`child-menu-${childIdx}`}>
                                      <a href={`/${menu.urlSegment}/${childMenu.SilverStripeSiteTree.urlSegment}`}>{childMenu.SilverStripeSiteTree.menuTitle}</a>
                                    </li>
                                ))}
                            </ul>
                          </li>
                        )}
                      </SlideToggle>
                ):(
                    <li>
                        <a href={`/${menu.urlSegment}`}>{menu.menuTitle}</a>
                    </li>
                )}
                </React.Fragment>
              ))}
            </ul>
          )}          
          <a href="/explore/" className={`btn btn-black btn-big${!logged ? ' d-none' : ''}`}>
            EXPLORE BIRDY
          </a>
          {logged ? (
            <ul className={`mSidebar-sub-menu${!logged ? ' d-none' : ''}`}>
              <li>
                <a href="/account">Settings</a>
              </li>
              <li>
                <a href="/logout/">Logout</a>
              </li>
            </ul>
          ) : (
            <ul className={`mSidebar-sub-menu${!logged ? ' d-none' : ''}`}>
              <li>
                <a href="/login">Login</a>
              </li>
              <li>
                <a href="/register/">Register</a>
              </li>
            </ul>
          )}
          <SocialLinks />
        </div>
      </Modal>
    </>
  )
}

export default MainSidebar
