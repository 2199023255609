import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const SocialLinks = () => {
    const raw = useStaticQuery(graphql`
    {
        allSilverStripeDataObject(
            filter: {
              className: { eq: "Undigital__Objects__GlobalConfig" }
            }
          ){
        nodes {
          UndigitalGlobalConfig {
            instagram
            googlePlus
            facebook
            pinterest
            twitter
            youtube
          }
        }
      }
    }`);
    const configNode = raw.allSilverStripeDataObject.nodes;
    const globalConfig = configNode[0].UndigitalGlobalConfig;
    var facebook = globalConfig.facebook;
    var googlePlus = globalConfig.googlePlus;
    var instagram = globalConfig.instagram;
    var pinterest = globalConfig.pinterest;
    var twitter = globalConfig.twitter;
    var youtube = globalConfig.youtube;
  return (
        <div className="social-group">
            {instagram && (
            <a href={instagram} target="_blank">
                <svg width='30' height='30'>
                    <use xlinkHref='/img/icons.svg#icon-social-ins'></use>
                </svg>
            </a>
            )}
            {pinterest && (
            <a href={pinterest} target="_blank">
                <svg width='30' height='29.97'>
                    <use xlinkHref='/img/icons.svg#icon-social-pin'></use>
                </svg>
            </a>
            )}
            {facebook && (
            <a href={facebook} target="_blank">
                <svg width='30' height='28.85'>
                    <use xlinkHref='/img/icons.svg#icon-social-fb'></use>
                </svg>
            </a>
            )}
        </div>
)
}

export default SocialLinks
