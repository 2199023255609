import React from "react"
import { Modal } from "react-bootstrap"

const MobileSearchModal = ({ show, handleClose }) => {
    const saveSearch = (event) => {
        event.preventDefault()
        let keyword = event.target.elements.keyword.value
        if (typeof window !== "undefined" && window.localStorage) {
            let old_keywords = JSON.parse(window.localStorage.getItem('keywords'))
            if (Array.isArray(old_keywords)){
                if(!old_keywords.includes(keyword)){
                    old_keywords.push(keyword)
                    window.localStorage.setItem('keywords', JSON.stringify(old_keywords))
                }
            }else{
                old_keywords = [keyword]
                let _data = JSON.stringify(old_keywords)
                window.localStorage.setItem('keywords', _data)
            }
        }
        if (typeof document !== "undefined") {
            document.getElementById("designsfiltermobile").submit()
        }
    }    
    var old_keywords = null
    if (typeof window !== 'undefined') {
        old_keywords = JSON.parse(window.localStorage.getItem('keywords'))
        if (Array.isArray(old_keywords)){
            old_keywords = old_keywords.reverse()
        }
    }
    
  return (
    <Modal
      className="mSidebar pl-0"
      show={show}
      onHide={handleClose}
      animation={true}
    >
      <div className="mSidebar-inner">
        <Modal.Header closeButton={true}></Modal.Header>
        <div className="search-modal-container">
          <form className="search-box" id="designsfiltermobile" action="/search/"
           onSubmit={event => { saveSearch(event) }}
          >
            <input
              name="keyword"
              className="filter-text"
              placeholder="Search"
            />
            <div className="filter-icon">
              <img src="/img/explore/search.svg" />
            </div>
          </form>
         {(old_keywords && old_keywords.length > 0) && (
          <div className="recently-searched">
            <p>Recently Searched</p>
            <ul>
                {old_keywords.map((_keyword, idx) => (
                        <li key={`keyword-${idx}`}><a href={`/search/?keyword=${_keyword}`}>{_keyword}</a></li>
                ))}
            </ul>
          </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default MobileSearchModal
