import React, { useState, useEffect } from "react"
import { isLoggedIn } from "../../../services/auth"
import MobileSearchModal from "../Modal/MobileSearchModal"

const FooterNavMobile = () => {
  const [logged, setLogged] = useState(false)
  const [searchShow, setSearchShow] = useState(false)

  useEffect(() => {
      const isLogged = isLoggedIn()
      setLogged(isLogged)
  }, []);
  return (
    <div className={`d-sm-none footer-mobile-navigator${!logged ? ' d-none' : ''}`}>
      <div className="content">
        <div className="item">
          <a href="/" className="mobile-nav-btn">
            <img src="/img/home.svg" alt="Home"/>
          </a>
        </div>
        <div className="item">
          <a className="mobile-nav-btn" onClick={() => setSearchShow(true)}>
            <img src="/img/search.svg" alt="Search"/>
          </a>
        </div>
        <MobileSearchModal show={searchShow} handleClose={setSearchShow} />
        <div className="item">
          <a href="/account/my-boards/" className="mobile-nav-btn">
            <img src="/img/boards.svg" alt="Boards"/>
          </a>
        </div>
        <div className="item">
          <a href="/account/" className="mobile-nav-btn">
            <img src="/img/account.svg" alt="Account"/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default FooterNavMobile
